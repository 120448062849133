/**
 * This is the entry point for Feature Hub App integration
 */

import React from 'react';
import { FeatureAppDefinition, FeatureAppEnvironment, FeatureServices } from '@feature-hub/core';
import { ReactFeatureApp } from '@feature-hub/react';
import type { Logger } from '@feature-hub/logger';
import { ContentServiceV1 } from '@oneaudi/content-service';
import { AsyncSsrManagerV1 } from '@feature-hub/async-ssr-manager';
import { SerializedStateManagerV1 } from '@feature-hub/serialized-state-manager';
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import type { OneGraphServiceV1 } from '@oneaudi/onegraph-service';
import type { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import { I18NServiceV1 } from '@oneaudi/i18n-service';
import { RenderModeServiceV1 } from '@oneaudi/render-mode-service';
import { ThemeProvider } from '@oneaudi/unified-web-common';
import AsyncFeatureApp from './AsyncFeatureApp';
import { CarlineAvailableStock, I18nMessages } from './ContentType';
import { mapHeadlessContent } from './utils/mapHeadlessContent';
import { createInitialState } from './utils/stateUtils';
import { FeatureAppContextProvider } from './context/FeatureAppContext';

export interface Dependencies extends FeatureServices {
  readonly 's2:async-ssr-manager'?: AsyncSsrManagerV1;
  readonly 's2:logger': Logger;
  readonly 'audi-content-service': ContentServiceV1;
  readonly 's2:serialized-state-manager'?: SerializedStateManagerV1;
  readonly 'audi-tracking-service'?: TrackingServiceV2;
  readonly 'onegraph-service': OneGraphServiceV1;
  readonly 'gfa:locale-service': GfaLocaleServiceV1;
  readonly 'dbad:audi-i18n-service': I18NServiceV1;
  readonly 'audi-render-mode-service': RenderModeServiceV1;
}

export interface InitialState {
  readonly i18nMessages: I18nMessages;
  readonly carlineAvailableStock?: CarlineAvailableStock;
}

export type AsyncStateHolder = (() => Promise<InitialState>) | InitialState | undefined;

const featureAppDefinition: FeatureAppDefinition<ReactFeatureApp, Dependencies> = {
  dependencies: {
    featureServices: {
      's2:logger': '^1.0.0',
      'audi-content-service': '^1.0.0',
      'onegraph-service': '^1.0.0',
      'gfa:locale-service': '^1.0.0',
      'dbad:audi-i18n-service': '^1.0.0',
    },
    externals: {
      react: '*',
      'styled-components': '*',
    },
  },

  optionalDependencies: {
    featureServices: {
      's2:async-ssr-manager': '^1.0.0',
      's2:serialized-state-manager': '^1.0.0',
      'audi-tracking-service': '^2.0.0',
      'audi-render-mode-service': '^1.0.0',
    },
  },

  create: ({ featureServices, featureAppId }: FeatureAppEnvironment<Dependencies, void>) => {
    const {
      'audi-tracking-service': trackingService,
      'gfa:locale-service': localeService,
      's2:logger': s2logger,
      'audi-content-service': contentService,
      's2:async-ssr-manager': asyncSsrManager,
      's2:serialized-state-manager': serializedStateManager,
      'onegraph-service': oneGraphService,
      'dbad:audi-i18n-service': i18nService,
      'audi-render-mode-service': renderModeService,
    } = featureServices;

    if (trackingService) {
      trackingService.featureAppName = 'oneaudi-feature-app-subpage-navigation';
    }

    const logger = s2logger || console;
    logger.info('Feature App created.');

    const initialContent = contentService.getContent();
    logger.info('Feature App created, initialContent', initialContent);

    const content = mapHeadlessContent(initialContent);
    logger.info('Feature App created, content', content);

    let asyncStateHolder: AsyncStateHolder;
    if (asyncSsrManager) {
      // on the server
      asyncSsrManager.scheduleRerender(
        (async () => {
          asyncStateHolder = await createInitialState(
            oneGraphService,
            localeService,
            content,
            i18nService,
            logger,
          );
          serializedStateManager?.register(() => JSON.stringify(asyncStateHolder));
        })(),
      );
    } else {
      // on the client
      const serializedState = serializedStateManager?.getSerializedState();
      if (serializedState) {
        asyncStateHolder = JSON.parse(serializedState) as InitialState;
      } else {
        logger?.warn(
          `Serialized state not found!. Possible reasons: \n 1. Running app in dev mode using the "audi-static-demo-integrator" which does not support SSR \n 2. Running app in prod mode and SSR is broken`,
        );
        asyncStateHolder = () =>
          createInitialState(oneGraphService, localeService, content, i18nService, logger);
      }
    }

    return {
      render: () => {
        return (
          <ThemeProvider theme={{ name: 'light' }}>
            <FeatureAppContextProvider
              featureAppId={featureAppId}
              logger={logger}
              trackingService={trackingService}
              contentService={contentService}
              renderModeService={renderModeService}
              localeService={localeService}
              i18nService={i18nService}
              oneGraphService={oneGraphService}
            >
              <AsyncFeatureApp asyncStateHolder={asyncStateHolder} />
            </FeatureAppContextProvider>
          </ThemeProvider>
        );
      },
    };
  },
};

export default featureAppDefinition;
